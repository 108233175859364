import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

export const Container = styled.div`
  position: fixed;
  /* align-self: center; */
  height: 100%;
  width: 100%;
  top: 0;
  background: white;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 960px) {
    overflow-y: scroll;
  }
`

export const Link = styled(GatsbyLink)`
  color: black;
  text-decoration: none;
`
export const Btn = styled(Link)`
  font-family: "Times New Roman", Times, serif;
  @media (max-width: 960px) {
    font-size: 22px;
  }
  background: white;
  border-color: none;
  border-style: none;
  border-width: none;
  border-radius: none;
  :hover {
    font-style: italic;
    color: black;
  }
  cursor: pointer;
`
export const MenuBar = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${props => props.theme.headerHeight};
  @media (max-width: 960px) {
    height: 5vh;
  }
  background: white;
  overflow: hidden;
`

export const PDesk = styled.p`
  @media (max-width: 961px) {
    display: none;
  }
`
export const MobileP = styled.p`
  @media (min-width: 961px) {
    display: none;
  }
`

export const Row = styled.div`
  min-height: 0;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`
export const Rows = styled.div`
  top: ${props => props.theme.headerHeight};
  padding-top: 3.5vh;
`

const Info = () => (
  <Layout>
    <SEO
      title=" ⠀ "
      keywords={[
        `Julia`,
        `Rosenstock`,
        `Portfolio`,
        `Berlin`,
        `multimedia`,
        `Video`,
        `Art`,
        `UDK`,
        `Adbk`,
        `Stage Design`,
        `New Media`,
        `Photography`,
      ]}
    />
    <Container>
      <MenuBar>
        <Link to="/">Info</Link>
        <Btn to="/">CLOSE</Btn>
      </MenuBar>
      <PDesk>
        <Row>&#8203;</Row>
        <Row>
        {" "}
        Julia Rosenstock is a Berlin-based multimedia artist.
        </Row>
        <Row>&#8203;</Row>
        <Row>
          Feel free to <a href="mailto:mail@juliarosenstock.com">contact me</a>{" "}
          for full portfolio or CV
        </Row>
        <Row>
        Keep in touch <a href="https://www.instagram.com/jurosenstock/">@jurosenstock</a>
        </Row>
      </PDesk>

      <MobileP>
        <Row>&#8203;</Row>
        <Row> Julia Rosenstock is a Berlin-based</Row>
        <Row> multimedia artist.</Row>
        <Row>&#8203;</Row>
        <Row>
         Please <a href="mailto:mail@juliarosenstock.com">contact me</a> for full {" "} portfolio or CV
        </Row>
        <Row>
        Keep in touch <a href="https://www.instagram.com/jurosenstock/">@jurosenstock</a>
        </Row>
      </MobileP>
    </Container>
  </Layout>
)

export default Info
